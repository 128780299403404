import Skeleton from 'react-loading-skeleton'

type Props = {
  className?: string
}

export const ArtistSkeleton = ({ className = '' }: Props) => {
  return (
    <div className={`group flex flex-col ${className}`}>
      <div className='aspect-w-4 aspect-h-4'>
        <Skeleton width='100%' height='100%' borderRadius={4} />
      </div>
      <div className='mt-3'>
        <Skeleton
          className='lg:min-w-[138px]'
          containerClassName='h-[24px] flex'
          width='80%'
          height={16}
        />
        <Skeleton width={48} height={16} containerClassName='h-[22px] flex' />
      </div>
    </div>
  )
}
