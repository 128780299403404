import classNames from 'classnames'
import { StyledChildren } from 'components/abstract/StyledChildren'
import { Button } from 'components/common/Button'
import { Icon } from 'components/common/Icon'
import { debounce } from 'debounce'
import { detect } from 'detect-browser'
import useTranslation from 'next-translate/useTranslation'
import Link from 'next/link'
import {
  UIEvent,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

export interface CarouselProps {
  title?: string
  viewAllHref?: string
  viewAllLabel?: string
  hasPagination?: boolean
  children?: React.ReactNode
  className?: string
  headerClassName?: string
  bodyContainerClassName?: string
  bodyClassName?: string
  headerContent?: React.ReactNode
  titleClassName?: string
  mobileOnly?: boolean
  grid?: {
    xxs?: string
    xs?: string
    sm?: string
    md?: string
    lg?: string
    xl?: string
  }
  /**
   * Handler that is called when the user reaches the end of the carousel.
   */
  onCarouselEnd?: VoidFunction
}

export const Carousel = memo(function ({
  children,
  title,
  viewAllHref,
  viewAllLabel = 'View all',
  hasPagination = false,
  className,
  headerClassName = '',
  bodyContainerClassName,
  bodyClassName = '',
  headerContent,
  titleClassName,
  mobileOnly = false,
  grid,
  onCarouselEnd,
}: CarouselProps) {
  const { t } = useTranslation('common')
  const carouselRef = useRef<HTMLDivElement>(null)
  const [disabledLeft, setDisabledLeft] = useState(true)
  const [disabledRight, setDisabledRight] = useState(false)
  const [nrOfPages, setNrOfPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const browser = detect()

  useEffect(() => {
    if (carouselRef.current) {
      const { scrollWidth, clientWidth } = carouselRef.current
      setNrOfPages(Math.ceil(scrollWidth / clientWidth))
    }
  }, [])

  const isIOSSafari = useMemo(() => {
    return (
      ['iOS', 'Mac OS'].includes(browser?.os || '') &&
      ['ios', 'safari'].includes(browser?.name || '')
    )
  }, [browser?.os, browser?.name])

  const scrollToPage = useCallback(
    (page: number) => {
      const carousel = carouselRef.current

      if (carousel) {
        const gap = 16
        const paddingLeft = parseInt(getComputedStyle(carousel).paddingLeft)
        const paddingRight = parseInt(getComputedStyle(carousel).paddingRight)
        const { clientWidth, scrollWidth } = carousel
        const canScroll = scrollWidth > clientWidth

        if (!canScroll) return null

        const left =
          page * (clientWidth - paddingLeft - paddingRight) + page * gap

        carousel.scrollTo({
          left,
          behavior: 'smooth',
        })

        if (isIOSSafari) {
          carousel.style.transform =
            page === nrOfPages - 1
              ? `translateX(-${paddingRight}px)`
              : 'translateX(0)'
        }

        if (page >= 0 && page < nrOfPages) {
          setCurrentPage(page)
        }

        setDisabledLeft(left <= 0)
        setDisabledRight(left + clientWidth >= scrollWidth)
      }
    },
    [nrOfPages, isIOSSafari]
  )

  const onScroll = useCallback(
    (event: UIEvent<HTMLDivElement>) => {
      console.log('onScroll')
      const target = event.target as HTMLDivElement

      const scrollPercentage =
        (100 * target.scrollLeft) / (target.scrollWidth - target.clientWidth)

      setDisabledLeft(scrollPercentage === 0)
      setDisabledRight(scrollPercentage === 100)

      if (scrollPercentage >= 99) {
        onCarouselEnd?.()
      }
    },
    [onCarouselEnd]
  )

  return (
    <div className={`relative w-full overflow-x-visible ${className}`}>
      <header
        className={`container mb-4 flex w-full justify-between sm:mb-8 xl:mb-3 ${headerClassName}`}
      >
        {headerContent}

        <div className='inline-flex items-center space-x-6'>
          {title && (
            <h4
              className={classNames(
                titleClassName ||
                  'font-body text-lg font-medium tracking-normal sm:font-display sm:text-2xl'
              )}
            >
              {title}
            </h4>
          )}

          {viewAllHref && (
            <Button
              type='link'
              href={viewAllHref}
              pill
              style='outline'
              className='hidden sm:flex'
            >
              {viewAllLabel}
            </Button>
          )}
        </div>

        {viewAllHref && (
          <Link
            href={viewAllHref}
            className='flex items-center text-sm font-medium text-secondary-default transition-colors duration-200 hover:underline sm:hidden'
          >
            {viewAllLabel}
            <Icon name='arrow-chevron-forward' className='ml-2 text-base' />
          </Link>
        )}

        <div className='icon hidden space-x-2 sm:flex '>
          <Button
            pill
            style='outline'
            onClick={() => {
              scrollToPage(currentPage - 1)
            }}
            disabled={disabledLeft}
            aria-label={t('prev_page')}
            className='max-w-[40px]'
          >
            <span className='sr-only'>{t('prev_page')}</span>
            <Icon name='back-arrow' className='icon' />
          </Button>

          <Button
            pill
            style='outline'
            onClick={() => {
              scrollToPage(currentPage + 1)
            }}
            disabled={disabledRight}
            aria-label={t('next_page')}
            className='max-w-[40px]'
          >
            <span className='sr-only'>{t('next_page')}</span>
            <Icon name='forward-arrow' />
          </Button>
        </div>
      </header>

      <div
        className={classNames(
          '2xl:p-container relative w-full',
          bodyContainerClassName
        )}
      >
        <div
          ref={carouselRef}
          className={classNames(
            'sm:no-scrollbar sm:p-container relative flex w-full space-x-4 overflow-x-auto px-6 2xl:px-0',
            bodyClassName
          )}
          onScroll={debounce(onScroll, 100)}
        >
          <StyledChildren
            className={classNames(
              'carousel-home flex flex-shrink-0 flex-col',
              {
                '[@media(max-width:339px)]:w-[calc(90%_-_2_/_3_*_theme(margin.4))]':
                  !grid?.xxs,
                'w-[calc(50%_-_1_/_2_*_theme(margin.4))]': !grid?.xs,
                'sm:w-[calc(33.33%_-_2_/_3_*_theme(margin.4))]': !grid?.sm,
                'md:w-[calc(25%_-_3_/_4_*_theme(margin.4))]':
                  !mobileOnly && !grid?.md,
                'lg:w-[calc(20%_-_4_/_5_*_theme(margin.4))]':
                  !mobileOnly && !grid?.lg,
                'xl:w-[calc(16.66%_-_4_/_5_*_theme(margin.4))]':
                  !mobileOnly && !grid?.xl,
              },
              grid?.xxs,
              grid?.xs,
              grid?.sm,
              grid?.md,
              grid?.lg,
              grid?.xl
            )}
          >
            {children}
          </StyledChildren>
        </div>
      </div>

      {hasPagination && (
        <ul className='mt-10 flex justify-center space-x-3 sm:hidden'>
          {Array.from(Array(nrOfPages), (_, index) => (
            <li key={index}>
              <a
                href='#'
                className={`block h-2 w-2 cursor-default rounded-full bg-cool-400 transition-colors duration-200 ${
                  index === currentPage && 'bg-cool-900'
                }`}
              ></a>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
})

Carousel.displayName = 'Carousel'
