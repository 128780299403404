import classNames from 'classnames'
import { Carousel } from 'components/carousels/Carousel'
import useTranslation from 'next-translate/useTranslation'
import { ContainerElement } from 'types'

export function SearchedArtworksContainer({
  children,
  className,
}: ContainerElement) {
  const { t } = useTranslation()

  return (
    <Carousel
      title={t('common:your_recent_searches')}
      className={classNames(className, 'py-6 md:py-8')}
    >
      {children}
    </Carousel>
  )
}
