import Skeleton from 'react-loading-skeleton'

type Props = {
  className?: string
}

export const ArtworkSkeleton = ({ className }: Props) => {
  return (
    <div className={`group flex flex-col ${className}`}>
      <div className='aspect-h-5 aspect-w-4 flex items-center justify-center rounded-[4px] bg-warm-50'>
        <div className='flex items-center justify-center'>
          <div className='flex h-13/20 w-13/20 items-center duration-300 ease-in-out group-hover:scale-105'>
            <div className='flex h-full w-full items-center drop-shadow-[0_4px_5px_rgba(38,35,56,0.15)]'>
              <ArtworkImageSkeleton />
            </div>
          </div>
        </div>
      </div>

      <div className='mb-2.5 mt-4 flex flex-col'>
        <ArtworkNameSkeleton />

        <ArtworkArtistSkeleton />
      </div>

      <div className='flex space-x-2'>
        <ArtworkPriceSkeleton />

        <ArtworkPriceSkeleton />
      </div>
    </div>
  )
}

export function ArtworkImageSkeleton() {
  return (
    <Skeleton
      className='absolute left-0 top-0 h-full w-full'
      containerClassName='relative h-full w-full'
    />
  )
}

export function ArtworkNameSkeleton() {
  return (
    <Skeleton
      className='h-4 w-3/4 lg:min-w-[138px]'
      containerClassName='h-6 w-full'
      borderRadius={8}
    />
  )
}

export function ArtworkArtistSkeleton() {
  return (
    <Skeleton
      className='h-4 w-3/4 lg:min-w-[138px]'
      containerClassName='h-[22px] w-full'
      borderRadius={8}
    />
  )
}

export function ArtworkPriceSkeleton() {
  return (
    <Skeleton
      className='h-full'
      containerClassName='h-7 flex'
      width={75}
      borderRadius={8}
    />
  )
}
