export const chunkArray = <T>(items: T[], chunkSize = 2) =>
  items.reduce((chunks: T[][], item: T, index) => {
    const chunk = Math.floor(index / chunkSize)
    chunks[chunk] = ([] as T[]).concat(chunks[chunk] || [], item)
    return chunks
  }, [])

export const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (x, i) => i + start)

export const getRandomArray = <T = unknown>(inputArr: T[], n: number) => {
  const shuffled = inputArr.sort(() => 0.5 - Math.random())

  return shuffled.slice(0, n)
}
