import { Children, cloneElement, isValidElement } from 'react'

type Props = {
  children?: React.ReactNode
  className?: string
}

export const StyledChildren = ({ children, className }: Props) => {
  return (
    <>
      {Children.map(children, child => {
        if (isValidElement(child)) {
          return cloneElement(child, {
            className: `${child.props.className} ${className}`,
          } as any)
        }
        return child
      })}
    </>
  )
}
