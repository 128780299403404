import classNames from 'classnames'
import Skeleton from 'react-loading-skeleton'
import { PropsWithClassName } from 'types'

type Props = {
  keepAspectRatio?: boolean
} & PropsWithClassName

export function ReleaseCardSkeleton({ className, keepAspectRatio }: Props) {
  return (
    <div className={className}>
      <Skeleton
        className='h-full w-full rounded'
        containerClassName={classNames(
          'flex items-center justify-center',
          keepAspectRatio
            ? 'aspect-1'
            : 'aspect-4/3 [@media(min-width:369px)]:aspect-1'
        )}
      />

      <Skeleton
        className='h-full w-full rounded'
        containerClassName='mt-4 h-3 flex w-[60%]'
      />

      <Skeleton
        className='h-full w-full rounded'
        containerClassName='mt-2 mb-2 flex h-3 w-[80%]'
      />
    </div>
  )
}
