import { ArtistSkeleton } from 'components/artists/ArtistSkeleton'
import { ArtworkSkeleton } from 'components/artwork/ArtworkSkeleton'
import { ReleaseCardSkeleton } from 'components/release-calendar/ReleaseCardSkeleton'
import { range } from '../../utils/arrays'

type Props = {
  type: 'artwork' | 'artist' | 'release'
  count?: number
  className?: string
}

export const Skeletons = ({ type, count = 6, className = '' }: Props) => {
  return (
    <>
      {range(0, count).map(i => {
        switch (type) {
          case 'artwork':
            return <ArtworkSkeleton key={i} className={className} />
          case 'artist':
            return <ArtistSkeleton key={i} className={className} />
          case 'release':
            return <ReleaseCardSkeleton key={i} className={className} />
        }
      })}
    </>
  )
}
